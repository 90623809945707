<template>
  <div class="navigatorDiv w-11 md:w-9 m-auto mt-2">
    <div class="p-inputgroup flex-1 inputGroup" style="display: none">
      <div class="labelDiv col-3">
        <span>{{ $t("Applicant_Type") }}</span
        ><span style="color: red">*</span>
      </div>

      <div class="flex flex-wrap inputDiv col-7 gap-3">
        <div class="flex align-items-center">
          <RadioButton
            v-model="Applicant_Type"
            inputId="Applicant_Type"
            name="Applicant_Type"
            value="1"
          />
          <label for="ingredient1" class="m-1">{{ $t("New_Applicant") }}</label>
        </div>
        <div class="flex align-items-center" style="pointer-events: none">
          <RadioButton
            v-model="Applicant_Type"
            inputId="Applicant_Type"
            disabled
            name="Applicant_Type"
            value="2"
          />
          <label for="ingredient2" class="m-1">{{ $t("Depletion_Failure") }}</label>
        </div>
      </div>
      <div
        v-if="validationErrors.selectedDegreeType && submitted"
        class="validationErro col-2"
      >
        {{ $t("Check_This_Field") }}
      </div>
    </div>
    <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("Degree_Type") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <Dropdown
          v-model="selectedDegreeType"
          :options="degreeTypesData"
          optionLabel="degreeDescription"

          :placeholder="degreeTypeDDLPlaceholder"
          :class="[
            'w-12 m-1',
            { 'p-invalid': validationErrors.selectedDegreeType && submitted },
          ]"
        />
      </div>
      <div
        v-if="validationErrors.selectedDegreeType && submitted"
        class="validationErro w-full"
      >
        {{ $t("Check_This_Field") }}
      </div>
    </div>
    <!-- <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("Degree") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <Dropdown
          v-model="selectedDegree"
          :options="degreesData"
          optionLabel="name"
          :loading="degreesData.length == 0 && selectedDegreeType != {}"
          :placeholder="degreeDDLPlaceholder"
          :class="[
            'w-12 m-1',
            { 'p-invalid': validationErrors.selectedDegree && submitted },
          ]"
        />
      </div>
      <div
        v-if="validationErrors.selectedDegree && submitted"
        class="validationErro w-full"
      >
        {{ $t("Check_This_Field") }}
      </div>
    </div> -->
    <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("Nationality") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <Dropdown
          v-model="selectedCountry"
          :options="countriesData"
          optionLabel="name"
          filter
          :placeholder="countryDDLPlaceholder"
          :class="[
            'w-12 m-1',
            { 'p-invalid': validationErrors.selectedCountry && submitted },
          ]"
          class="w-full m-1"
        />
      </div>
      <div
        v-if="validationErrors.selectedCountry && submitted"
        class="validationErro w-full"
      >
        {{ $t("Check_This_Field") }}
      </div>
    </div>
    <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("Passport_No") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <InputText
          :placeholder="insertPassportPlaceholder"
          v-model="Passport_No"
          @keydown="validatePassportNumber"
          @change="validatePassportNumber"
          @input="validatePassportNumber"
          :class="[
            'w-12 p-input-sm m-1',
            {
              'p-invalid': validationErrors.Passport_No && submitted,
            },
          ]"
        />
      </div>
      <div v-if="validationErrors.Passport_No && submitted" class="validationErro w-full">
        {{ $t("Check_This_Field") }}
      </div>
      <div
        v-if="validationErrors.not_valid_passport"
        class="validationErro col-12"
        style="padding: 0px !important"
      >
        {{ $t("notValidPassport") }}
      </div>
    </div>
    <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("Passport_Date") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <Calendar
          v-model="Passport_Date"
          dateFormat="yy-mm-dd"
          @hide="checkForPassportIssueDate"
          showIcon
          :class="[
            'w-12 m-1',
            {
              'p-invalid':
                (validationErrors.Passport_Date_not_correct ||
                  validationErrors.Passport_Date) &&
                submitted,
            },
          ]"
        />
      </div>
      <div
        v-if="validationErrors.Passport_Date && submitted"
        class="validationErro w-full"
      >
        {{ $t("Check_This_Field") }}
      </div>

      <div
        v-if="validationErrors.Passport_Date_not_correct"
        class="validationErro w-full"
        style="padding: 0px !important"
      >
        {{ $t("Passport_Date_not_correct") }}
      </div>
    </div>
    <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("Passport_Exp_Date") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <Calendar
          v-model="Passport_Exp_Date"
          @hide="checkForExpirationDuration"
          dateFormat="yy-mm-dd"
          showIcon
          :class="[
            'w-12 m-1',
            {
              'p-invalid':
                (validationErrors.Passport_Exp_Date ||
                  validationErrors.Passport_Exp_Date) &&
                submitted,
            },
          ]"
        />
      </div>
      <div
        v-if="validationErrors.Passport_Exp_Date && submitted"
        class="validationErro w-full"
      >
        {{ $t("Check_This_Field") }}
      </div>
      <div
        v-if="validationErrors.Passport_Exp_Date_not_correct"
        class="validationErro w-full"
        style="padding: 0px !important"
      >
        {{ $t("MSG_03") }}
      </div>
    </div>
    <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("E-Mail") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <InputText
          :placeholder="insertPassportPlaceholder"
          v-model="emailField"
          @keydown="validateEmail"
          @change="validateEmail"
          @input="validateEmail"
          :class="[
            'w-12 p-input-sm  m-1',
            {
              'p-invalid': validationErrors.emailField && submitted,
            },
          ]"
        />
      </div>
      <div v-if="validationErrors.emailField && submitted" class="validationErro w-full">
        {{ $t("Check_This_Field") }}
      </div>
      <div
        v-if="validationErrors.not_valid_email"
        class="validationErro w-full"
        style="padding: 0px !important"
      >
        {{ $t("notValidEmail") }}
      </div>
    </div>
    <div class="p-inputgroup flex-1 inputGroup" style="margin: 0px auto">
      <div class="col">
        <div class="regestrationGuideDiv w-full">
          {{ $t("regestrationGuide") }}
        </div>
      </div>
    </div>
    <div class="p-inputgroup flex-1 inputGroup">
      <div class="col">
        <Button
        disabled
          :label="registerBtnLabel"
          @click="saveRegestrationPage"
          class="btnClass w-full"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      submitted: false,
      Applicant_Type: "1",
      selectedDegreeType: {},
      selectedDegree: {},
      selectedCountry: {},
      Passport_No: "",
      Passport_Date: "",
      Passport_Exp_Date: "",
      emailField: "",
      validationErrors: {},
      isEgypt:1
    };
  },
  computed: {
    degreeTypeDDLPlaceholder() {
      return this.$t("degreeTypeDDLPlaceholder");
    },
    degreeDDLPlaceholder() {
      return this.$t("degreeDDLPlaceholder");
    },
    countryDDLPlaceholder() {
      return this.$t("countryDDLPlaceholder");
    },
    registerBtnLabel() {
      return this.$t("Submit");
    },
    degreeTypesData() {
      return this.$store.getters["Auth/getDegreeTypesData"];
    },
    degreesData() {
      return this.$store.getters["Auth/getDegreesData"];
    },
    countriesData() {
      return this.$store.getters["Auth/getCountriesData"];
    },
  },
  methods: {
    // checkForExpirationDuration() {
    //   var Passport_Exp_DT = new Date(this.Passport_Exp_Date);
    //   var todate_DT = new Date();

    //   if (
    //     Passport_Exp_DT.getMonth() -
    //       todate_DT.getMonth() +
    //       12 * (Passport_Exp_DT.getFullYear() - todate_DT.getFullYear()) <
    //     6
    //   ) {
    //     this.validationErrors["Passport_Exp_Date_not_correct"] = true;
    //   } else delete this.validationErrors["Passport_Exp_Date_not_correct"];
    // },
    checkForExpirationDuration() {
    var Passport_Exp_DT = new Date(this.Passport_Exp_Date);
    var todate_DT = new Date();

    // Calculate the difference in months between passport expiration date and today's date
    var monthsDifference =
        (Passport_Exp_DT.getFullYear() - todate_DT.getFullYear()) * 12 +
        Passport_Exp_DT.getMonth() -
        todate_DT.getMonth();

    if (monthsDifference < 6) {
        this.validationErrors["Passport_Exp_Date_not_correct"] = true;
    } else {
        delete this.validationErrors["Passport_Exp_Date_not_correct"];
    }
},
    checkForPassportIssueDate() {
      var Passport_date_DT = new Date(this.Passport_Date);
      var todate_DT = new Date();

      if (Passport_date_DT > todate_DT) {
        this.validationErrors["Passport_Date_not_correct"] = true;
      } else delete this.validationErrors["Passport_Date_not_correct"];
    },
    validateForm() {
      if (!this.selectedDegreeType.asCodeDegreeId)
        this.validationErrors["selectedDegreeType"] = true;
      else delete this.validationErrors["selectedDegreeType"];
      // if (!this.selectedDegree.code) this.validationErrors["selectedDegree"] = true;
      // else delete this.validationErrors["selectedDegree"];
      if (!this.selectedCountry.code) this.validationErrors["selectedCountry"] = true;
      else delete this.validationErrors["selectedCountry"];
      if (this.Passport_No == "") this.validationErrors["Passport_No"] = true;
      else delete this.validationErrors["Passport_No"];
      if (this.Passport_Date == "") this.validationErrors["Passport_Date"] = true;
      else delete this.validationErrors["Passport_Date"];
      if (this.Passport_Exp_Date == "") this.validationErrors["Passport_Exp_Date"] = true;
      else delete this.validationErrors["Passport_Exp_Date"];
      // if (!this.checkForExpirationDuration())
      //   this.validationErrors["Passport_Exp_Date_not_correct"] = true;
      // else delete this.validationErrors["Passport_Exp_Date_not_correct"];
      if (this.emailField == "") this.validationErrors["emailField"] = true;
      else delete this.validationErrors["emailField"];
      return !Object.keys(this.validationErrors).length;
    },
    validateEmail() {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailField)) {
        this.validationErrors["not_valid_email"] = true;
      } else delete this.validationErrors["not_valid_email"];
    },
    validatePassportNumber() {
      if (!/^[a-zA-Z0-9]{5,20}$/.test(this.Passport_No)) {
        this.validationErrors["not_valid_passport"] = true;
      } else delete this.validationErrors["not_valid_passport"];
    },
    // fetchDegreesByType() {
    //   this.$store.dispatch("Auth/fetchDegreesData", this.selectedDegreeType);
    // },
    saveRegestrationPage() {

      // this.submitted = true;
      // if (this.validateForm()) {

      //  const country = this.$store.getters["Fees/getApplicationCountry"]
      //    this.isEgypt = 1;
      //   this.$store.commit("showLoaderFn", true);
      //   this.$store
      //     .dispatch("Auth/ApplicantRegistration", {
      //       asCodeDegreeClassId: this.selectedDegreeType.asCodeDegreeId,
      //       asCodeDegreeId: 1,
      //       gsCountryNodeId: this.selectedCountry.code,
      //       applicantPassportNo: this.Passport_No,
      //       passportDate: this.Passport_Date,
      //       passportExpireDate: this.Passport_Exp_Date,
      //       applicantEmail: this.emailField,
      //       Applicant_Type: this.Applicant_Type,
      //       isEgypt: this.isEgypt
      //     })
      //     .then((res) => {
      //       if (res.status) {
      //         this.$swal.fire({
      //             icon: "success",
      //             confirmButtonColor: "#3085d6",
      //             confirmButtonText: this.$t("confirmButtonText"),
      //             text: this.$t("passwordLinkSentReg"),
      //           });
      //         this.$router.push("/login");
      //         this.$store.commit("showLoaderFn", false);
      //       }

      //     })
      //     .catch((error) => {
      //       this.$store.commit("showLoaderFn", false);
      //       // this.$toast.add({
      //       //   severity: "error",
      //       //   detail: this.$t(error.response.data.Message),
      //       //   life: 3000,
      //       // });
      //       this.$swal.fire({
      //         icon: "error",
      //         confirmButtonColor: "#3085d6",
      //         confirmButtonText: this.$t("confirmButtonText"),
      //         text: this.$t(error.response.data.Message),
      //       });
      //     });
      // }
    },
  },
  watch: {},
  created() {
    this.$store.dispatch("Fees/fetchApplicationCountry");

    this.$store.dispatch("Auth/fetchDegreeTypesData");
    this.$store.dispatch("Auth/fetchCountriesData");

  },
};
</script>

<style scoped>
.inputDiv {
  margin-inline-start: 0px !important;
  padding: 0px !important;
}
.validationErro {
  color: red;
  font-size: 0.8rem;
  line-height: 2;
}
.navigatorDiv {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: center;
}

.loginGuideSpan {
  inline-size: 330px;
  overflow-wrap: break-word;
  font-size: 1.4vw;
  margin: 10px auto;
}

.btnClass {
  width: 320px;
  margin: 5px auto;
  height: 45px;
  font-weight: bold;
}
.labelDiv {
  margin-inline: 10px;
  font-weight: bold;
  text-align: start;
  font-size: 1rem;
}

.inputGroup {
  margin: 3px auto;
  width: 90%;
}

.inputGroup > input {
  border-radius: 5px;
  margin-inline: 5px;
}

.regestrationGuideDiv {
  text-align: end;
  width: 330px;
  text-decoration: underline;
  letter-spacing: 0px;
  color: #028bff;
  text-transform: capitalize;
  text-align: center;
  font-size: 1rem;
}
</style>
